h2 {
    font-size: 16px;
}

.formGrid {
    background: #FFF;
}

.gridFooter {
    background: #F5F5F5;
    height: 64px;
    margin-top: -64px;
    position: relative;
    top: 100%;
}

.infoIcon {
    color: #005DB9;
    font-size: 32px;
}

.subText {
    color: #333;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.14px;
    padding-right: 20px;
}

.formGridSeparated {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: 72px;
}
.formGrid {
    padding-top: 44px; 
}

.formGridSeparated {
    padding-top: 17px;
}

.formGridItemSeparated {
    padding-top: 40px;
}

.formHeader {
    background: #FFF;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    font-family: Roboto;
    font-size: 48px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.48px;
    margin: 0;
    padding: 66px 15.3% 46px;
}

.simple-form > div {
    padding: 0;
}

.sublocationButton {
    margin: 48px 0;
}

.toolbarGrid {
    background: #F5F5F5;
}

.toolbarContainer {
    flex-direction: row-reverse;
}

button.formSubmitButton {
    text-transform: none;
}

.simple-form > div:first-child {
    padding-top: 0;
}

div.arrayInput {
    margin: 0;
}

h2 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.16px;
}