.ModalTitle {
    font-weight: bold;
    
    color: rgba(0, 0, 0, 0.87);
}

.MuiDialog-paperScrollPaper {
    max-height: calc(100% - 64px);
    min-height: 185px;
}

.MuiDialog-paperFullWidth {
    width: 328px!important;
}

.MuiDialog-paperWidthSm {
    max-width: 328px;
}