.helpModal {

}
.helpModal .MuiDialog-paperFullWidth {
    width: 504px!important;
}
    .helpModalContent {
        border-bottom: 1px solid #e0e0e0;
    }
    .helpModal .MuiDialogActions-root{
        border-top: 1px solid #e0e0e0;
    }
    .helpModalContentContainer {
        margin: 35px 12px 0 12px;
    }
        .helpModalTitle {
            font-size: 20px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: -0.2px;
            color: #212121;
            border-bottom: 1px solid #e0e0e0;
        }

        .helpModalIcon {
            width: 32px!important;
            height: 32px!important;
            font-size: 32px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: center;
            color: #005db9;
        }

        .helpModalArticleTitle {
            font-size: 20px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: -0.2px;
            color: #212121;
        }

        .helpModalTopic {
            margin-bottom: 40px;
        }
            .helpModalTopicTitle {
                font-size: 16px;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: -0.16px;
                color: #212121;
            }
            .helpModalTopicText {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: -0.14px;
                color: #666666;
            }
