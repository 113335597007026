.breadcrumbContainer {
    margin-bottom: 40px !important;
}

.breadcrumbBase {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.14px;
}

.breadcrumbLink {
    text-decoration: none;
    color:inherit
}

.breadcrumbCurrent {
    color: #005db9;
    font-weight: bold;
    margin: 0;
    cursor: pointer;
}