.deviceInfoModal {

}
.deviceInfoModal .MuiDialogContent-root {
    padding: 0;
}
.deviceInfoModal-content {
    margin: 25px 0 0 0;

}
.deviceInfoModal-header {
    padding: 0 40px 25px 40px;
}
.deviceInfoModal-content span{
    margin: 2px 0 2px 0;
}
    .deviceInfoModal-header-title {
        font-size: 10px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.1px;
        color: #212121
    }
    .deviceInfoModal-header-modelName {
        font-size: 20px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: -0.2px;
        color: #212121
    }


    .deviceInfoModal-content-row {
        min-height: 84px;
        padding: 0 40px 0 40px;
    }
        .deviceInfoModal-gray {
            background-color: #F5F5F5;
        }
        .deviceInfoModal-content-row-text {
            padding-top: 16px;
        }
            .deviceInfoModal-content-title {
                font-size: 10px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.6;
                letter-spacing: -0.1px;
                color: #666666;
            }
            .deviceInfoModal-content-text {
                font-size: 16px;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: -0.16px;
                color: #212121;
            }
.deviceInfoModal-content-address {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.14px;
    color: rgba(0, 0, 0, 0.87);
}
            .deviceInfoModal-address-row {
                margin-bottom: 18px;
            }

/* Error Code Details */
.errorDetials-errorTitle {
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.2;
    letter-spacing: -0.2px;
    color: #212121;
}

.errorDetials-topicTitle {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: -0.16px;
    color: #212121;
}

.errorDetials-topicItem {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.14px;
    color: #666666
}
.errorDetials-topicRow {
    padding-bottom: 30px;
}

.errorDetials-topicItem {
    padding-left: 1.2em;
    margin:0;
}

.errorDetials-gray {
    background-color: #F5F5F5;
}
.errorDetails-icon {
    border-radius: 100%;
    width: 20px !important;
    height: 20px;
    padding: 10px;
    font-size: 20px;
    color: #666;
    display: inline-block;
    margin-right: 16px;
    vertical-align: super;
}
.errorDetails-color-red {
    background-color: #FFCCCC;
}
.errorDetails-color-orange {
    background-color: #FFE7D0;
}
.errorDetails-color-green {
    background-color: #CCF3CD;
}