.monitorCard {
    width: 328px;
    height: 184px;
    display: inline-block;
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    margin: 0 0 24px 24px;
    position: relative;
}

@media only screen and (max-width: 959px) {
    .monitorCard {
        margin: 24px 24px 0 0;
    }
}

.monitorCardNoBorder {
    width: 328px;
    height: 184px;
    display: inline-block;
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    margin: 0 0 24px 0;
    position: relative;
}

.monitorCardBorder {
    width: 328px;
    height: 184px;
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    margin-right: 24px !important;
    margin-left: 24px !important;
    margin-bottom: 24px !important;
    position: relative;
}

.monitorCardTitle {
    padding-left: 5px;
    padding-right: 5px;
}

.monitorCardMessage {
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: bold;
    line-height: 1.6;
    letter-spacing: -0.1px;
    color: rgba(0, 0, 0, 0.87);
    margin-left: 10px;
}

.monitorCardValue {
    font-size: 64px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: -0.64px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    transform: translateY(15px);
}

.monitorCardDate {
    position: absolute;
    bottom: 0;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.05);
    width: 100%;
    display: flex;
    align-items: center;
}

.monitorCardDateIcon {
    margin: 0;
    margin-left: 7px;
    margin-right: 7px;
}

.monitorCardDate span {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.12px;
    color: rgba(0, 0, 0, 0.6);
}

.monitorCardIcon {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #005db9;
    float: right;
    top: -3px;
    position: relative;
    cursor: pointer;
    margin-right: 5px;
}


.telemetryCardMenu {
    top: 42px !important;
    left: -258px !important;
}

    .telemetryCardMenu .MuiList-padding {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    .telemetryCardMenu .MuiMenuItem-root {
        padding-bottom: 0px !important;
    }

    .telemetryCardMenu .MuiListItem-gutters {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
