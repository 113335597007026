.chartContainer {
    margin-bottom: 24px !important;
}

.chartDiv {
    margin: auto;
    max-width: 1032px;
    /* min-width: 1032px; */
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
}

.chartHeader {
    padding: 12px 12px 0 12px;
}

.chartHeaderIcon {
    width: 16px;
    height: 16px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #005db9;
    cursor: pointer;
    float: right;
    margin-left: 28px;
}

.chartContent {
    padding-bottom: 10px;
}

.chartHeaderTitle {
    font-size: 10px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.1px;
    color: rgba(0, 0, 0, 0.87);
    position: relative;
    top: -3px;
}

.legendTitle {
    font-size: 20px;
    font-weight: 900;
    line-height: 1.2;
    letter-spacing: -0.2px;
    color: rgba(0, 0, 0, 0.87);
}

.legendContainer {
    width: 298px;
    padding-bottom:36px
}

.thickLine {
    border-width: 2px;
}

.thinLine {
    border-width: 1px;
}

.legendLine {
    border-style: solid;
    width: 40px;
    height: 0px;
    display: inline-block;
    vertical-align: middle;
}

.legendLabel {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.16px;
    color: #212121;
    margin-left: 16px;
    display: inline;
    font-weight: 400;
}

.disabledCard, .disabledCard * {
    color: #C0C0C0;
}