.ModalTitle {
    font-weight: bold;
    
    color: rgba(0, 0, 0, 0.87);
}

.MuiDialog-paperScrollPaper {
    max-height: calc(100% - 64px);
    min-height: 292px;
}

.MuiDialog-paperFullWidth {
    width: 328px!important;
}

.MuiDialog-paperWidthSm {
    max-width: 328px;
}

.MuiTypography-h6 {
    font-size: 20px;
    font-family: sans-serif;
    font-size: 20px;
    /* font-weight: normal; */
    font-weight: 900 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.2px;
    color: rgba(0, 0, 0, 0.87);
}

.ModalText {
    padding: 0px 24px 24px 24px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.16px;
    color: rgba(0, 0, 0, 0.6);
}

.ModalButton {
    font-size: 14px;
    /* font-weight: normal; */
    font-weight: bold!important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -0.14px;
    color: #005db9!important;
    text-transform: none!important;
}