    .errorLogContainer {
        min-width: 328px;
        height: 416px;
        overflow-y: auto;
        border-radius: 4px;
        border: solid 1px rgba(0, 0, 0, 0.12);
        background-color: #ffffff;
        
        display: inline-block;
        position: relative;
        margin-bottom: 24px;

    }
        .errorLogHeader {
            padding: 10px;
            padding-left: 15px;
            border-bottom: solid 1px rgba(0, 0, 0, 0.12);
        }
        .errorLogHeader p {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: -0.1px;
            color: rgba(0, 0, 0, 0.87);
        }
        .oddCard {
            background-color: rgba(0, 0, 0, 0.04);
        }
        .errorLogCard {
            margin:0;
            height: 72px;
            width: 100%;
        }
        .errorLogCardIcon {
            width: 20px!important;
            height: 20px;
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: center;
            color: rgba(0, 0, 0, 0.54);
            
            float: left;
            margin: 12px;
            margin-top: 15px;
        }
        .errorLogCardDetails {
            float: left;
            margin: 12px;
            margin-top: 15px;
        }
        .errorLogCardName {
            font-size: 14px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: -0.14px;
            color: rgba(0, 0, 0, 0.87);
        }
        .errorLogCardDate {
            font-size: 11px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: -0.1px;
            color: rgba(0, 0, 0, 0.6);
        }